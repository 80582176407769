var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "shortcut-menu" },
    [
      _c("div", { staticClass: "title-line" }, [
        _c("span", { staticClass: "label-span" }, [_vm._v(" 快捷菜单 ")]),
        _c("span", { staticClass: "edit-span", on: { click: _vm.menuEdit } }, [
          _vm._v("编辑"),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "list-container" },
        [
          _vm.menuList.length
            ? _vm._l(_vm.menuList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "single-menu",
                    attrs: { title: _vm.generateTitle(item.displayText) },
                    on: {
                      click: function ($event) {
                        return _vm.routePathJump(item.href)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "icon-line" },
                      [
                        item.icon
                          ? _c("svg-icon", {
                              attrs: { "icon-class": item.icon },
                            })
                          : _c("svg-icon", {
                              attrs: { "icon-class": "zuzhiguanli" },
                            }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "label-line" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.generateTitle(item.displayText)) +
                          "\n        "
                      ),
                    ]),
                  ]
                )
              })
            : [
                _c("div", { staticClass: "empty-container" }, [
                  _c(
                    "div",
                    {
                      staticClass: "empty-detail",
                      on: { click: _vm.menuEdit },
                    },
                    [
                      _c("i", { staticClass: "el-icon-plus" }),
                      _c("div", { staticClass: "empty-label" }, [
                        _vm._v("暂未添加快捷菜单，点击“+”立即添加"),
                      ]),
                    ]
                  ),
                ]),
              ],
        ],
        2
      ),
      _vm.menuDialogShow
        ? _c("ShortMenuDialog", {
            attrs: { dataset: _vm.menuDialogShow },
            on: { close: _vm.dialogClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }