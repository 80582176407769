var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bms-workbench app-main" }, [
    _c("div", { staticClass: "shortcut-menu" }, [_c("ShortcutMenu")], 1),
    _c(
      "div",
      { staticClass: "personal-container" },
      [
        _c("div", { staticClass: "title-line" }, [_vm._v("个人")]),
        _c(
          "el-row",
          { staticClass: "detail-container", attrs: { gutter: 16 } },
          [
            _c("el-col", { attrs: { span: 8 } }, [_c("PersonalTodo")], 1),
            _c("el-col", { attrs: { span: 16 } }, [_c("PersonalSchedule")], 1),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "project-container" }, [
      _c("div", { staticClass: "title-line" }, [_vm._v("工程")]),
      _c("div", { staticClass: "detail-container" }, [
        _c("div", { staticClass: "single-card" }, [_c("SmartDevices")], 1),
        _c("div", { staticClass: "single-card" }, [_c("OrderList")], 1),
        _c("div", { staticClass: "single-card" }, [_c("InspectionList")], 1),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }