<template>
  <div class="shortcut-menu">
    <div class="title-line">
      <span class="label-span"> 快捷菜单 </span>
      <span class="edit-span" @click="menuEdit">编辑</span>
    </div>
    <div class="list-container">
      <template v-if="menuList.length">
        <div
          class="single-menu"
          @click="routePathJump(item.href)"
          v-for="(item, index) in menuList"
          :title="generateTitle(item.displayText)"
          :key="index"
        >
          <div class="icon-line">
            <svg-icon v-if="item.icon" :icon-class="item.icon" />
            <svg-icon v-else icon-class="zuzhiguanli" />
          </div>
          <div class="label-line">
            {{ generateTitle(item.displayText) }}
          </div>
        </div>
      </template>
      <template v-else>
        <div class="empty-container">
          <div class="empty-detail" @click="menuEdit">
            <i class="el-icon-plus"></i>
            <div class="empty-label">暂未添加快捷菜单，点击“+”立即添加</div>
          </div>
        </div>
      </template>
    </div>
    <ShortMenuDialog
      v-if="menuDialogShow"
      :dataset="menuDialogShow"
      @close="dialogClose"
    />
  </div>
</template>

<script>
import { generateTitle } from "@/utils/i18n";
import ShortMenuDialog from "@/components/ShortMenuDialog";
export default {
  name: "shortcut-menu",
  components: {
    ShortMenuDialog,
  },
  data() {
    return {
      currentUser: localStorage.getItem("application:lu"),
      currentTenant: this.$store.getters.currentTenant,
      menuList: [],
      menuDialogShow: false,
    };
  },
  created() {
    this.initDatas();
  },
  mounted() {
    this.$eventBus.$off("fresh-short-menu");
    this.$eventBus.$on("fresh-short-menu", () => {
      this.initDatas();
    });
  },
  methods: {
    generateTitle,
    initDatas() {
      try {
        const localDatas = localStorage.getItem("shortcut-mune-store");
        if (localDatas) {
          let localMenu =
            JSON.parse(localDatas)[
              `${this.currentUser}-${this.currentTenant}`
            ] || [];
          this.menuList = localMenu;
        }
      } catch (error) {
        console.log("初始化本地数据失败：", error);
      }
    },
    routePathJump(path, target = "_blank") {
      const { origin, pathname } = window.location;
      let targetUrl = path.startsWith("http")
        ? path
        : `${origin}${pathname}#${path}`;
      window.open(targetUrl, target);
    },
    menuEdit() {
      this.menuDialogShow = true;
    },
    dialogClose(fresh) {
      this.menuDialogShow = false;
      if (fresh === true) {
        this.initDatas();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.shortcut-menu {
  .title-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .label-span {
      font-size: 20px;
      font-weight: bold;
    }
    .edit-span {
      font-size: 12px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #2a61ff;
      cursor: pointer;
    }
  }
  .list-container {
    margin-top: 12px;
    height: 84px;
    display: flex;
    gap: 0 12px;
    .single-menu {
      cursor: pointer;
      height: 84px;
      width: 0;
      flex: 1;
      background: #ffffff;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .icon-line {
        height: 40px;
        width: 40px;
        font-size: 20px;
        background-color: #f4f7fe;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 6px;
        color: #2a61ff;
      }
      .label-line {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #252d3d;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 10px;
        text-align: center;
      }
    }
  }
  .empty-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 10px;
    .empty-detail {
      height: 100%;
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      align-items: center;
      padding: 20px 0;
      cursor: pointer;
      .el-icon-plus {
        color: #1a4cec;
        font-size: 20px;
        font-weight: bold;
      }
      .empty-label {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #a7b4c7;
      }
    }
  }
}
</style>