<template>
  <div class="bms-workbench app-main">
    <div class="shortcut-menu">
      <ShortcutMenu />
    </div>
    <div class="personal-container">
      <div class="title-line">个人</div>
      <el-row :gutter="16" class="detail-container">
        <el-col :span="8">
          <PersonalTodo />
        </el-col>
        <el-col :span="16">
          <PersonalSchedule />
        </el-col>
      </el-row>
    </div>
    <div class="project-container">
      <div class="title-line">工程</div>
      <div class="detail-container">
        <div class="single-card">
          <!-- 智能设备监测 -->
          <SmartDevices />
        </div>
        <div class="single-card">
          <!-- 工单超时提醒 -->
          <OrderList />
        </div>
        <div class="single-card">
          <!-- 巡检超时提醒 -->
          <InspectionList />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PersonalTodo from "./components/personalTodo.vue";
import PersonalSchedule from "./components/personalSchedule.vue";
import SmartDevices from "./components/smartDevices.vue";
import OrderList from "./components/orderList.vue";
import InspectionList from "./components/inspectionList.vue";
import ShortcutMenu from "./components/shortcutMenu.vue";
export default {
  name: "bms-workbench",
  components: {
    PersonalTodo,
    PersonalSchedule,
    SmartDevices,
    OrderList,
    InspectionList,
    ShortcutMenu,
  },
};
</script>

<style lang="less" scoped>
.bms-workbench {
  padding: 32px 16px 32px 40px;
  height: calc(100vh - 60px);
  overflow: auto;
  .title-line {
    font-size: 20px;
    font-weight: bold;
  }
  .personal-container {
    margin-top: 40px;
    color: #000000;
    .detail-container {
      margin-top: 16px;
      height: 573px;
    }
  }
  .project-container {
    margin-top: 40px;
    .detail-container {
      margin-top: 16px;
      display: flex;
      height: 573px;
      .single-card {
        flex: 1;
        background: #ffffff;
        border-radius: 4px;
      }
      .single-card + .single-card {
        margin-left: 16px;
      }
    }
  }
}
</style>